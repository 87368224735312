// Container Luar
.react-datepicker {
  @apply text-base text-gray-700 rounded-xl border-white border-2;
}

// Header
.react-datepicker__header {
  @apply bg-white border-white rounded-t-xl #{!important};
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  @apply text-blue-500 text-xl;
}

// Container Item Datepicker
.react-datepicker__year-wrapper {
  @apply justify-center items-center;
}

// Item Datepicker
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range .react-datepicker-year-header {
  @apply text-white font-bold bg-blue-500 hover:bg-blue-500;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  @apply text-white font-bold bg-blue-500 hover:bg-blue-700;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  @apply border-blue-500 mt-1.5;
}

.react-datepicker__year-dropdown-container select,
.react-datepicker__month-dropdown-container select {
  @apply border border-blue-400 px-4 py-2 rounded-xl;
  appearance: auto;
}

.react-datepicker__year-dropdown-container select option,
.react-datepicker__month-dropdown-container select option{
  @apply p-4;
}