.transparent {
  opacity: 0.4;
}

.icon-transition {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.arrow-position {
  right: 0;
  top: 50%;
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}

.rotate-icon {
  -ms-transform: translateY(0%) rotate(-180deg);
  transform: translateY(0%) rotate(-180deg);
}

.datatable-filter {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.datatable-filter label {
  margin-bottom: 0;
}

.datatable-items-per-page {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}

.datatable-items-per-page label {
  margin-bottom: 0;
}