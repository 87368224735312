@function hexToRgb($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root {
  --color-white0-payment: #ffffff;
  --color-white1-payment: #fcfcfc;
  --color-blue0-payment: #3cb7ff;
  --color-blue1-payment: #127cff;
  --color-blue2-payment: #c2e1f2;
  --color-blue3-payment: #0286d1;
  --color-blue4-payment: #edf3ff;
  --color-blue5-payment: #73c4f1;
  --color-blue0-rgb: #{hexToRgb(#3cb7ff)};
  --color-blue1-rgb: #{hexToRgb(#127cff)};
  --color-blue2-rgb: #{hexToRgb(#c2e1f2)};
  --color-blue3-rgb: #{hexToRgb(#0286d1)};
  --color-blue4-rgb: #{hexToRgb(#edf3ff)};
  --color-blue5-rgb: #{hexToRgb(#73c4f1)};
  --color-yellow0-payment: #edde5d;
  --color-yellow1-payment: #f09819;
  --color-yellow2-payment: #fe8718;
  --color-yellow0-rgb: #{hexToRgb(#edde5d)};
  --color-yellow1-rgb: #{hexToRgb(#f09819)};
  --color-yellow2-rgb: #{hexToRgb(#fe8718)};
  --color-red0-payment: #ff7373;
  --color-red1-payment: #e74242;
  --color-red2-payment: #f04019;
  --color-red3-payment: #ed5d5d;
  --color-red4-payment: #ea4242;
  --color-red5-payment: #e11f1f;
  --color-red6-payment: #ffc6c6;
  --color-red0-rgb: #{hexToRgb(#ff7373)};
  --color-red1-rgb: #{hexToRgb(#e74242)};
  --color-red2-rgb: #{hexToRgb(#f04019)};
  --color-red3-rgb: #{hexToRgb(#ed5d5d)};
  --color-red4-rgb: #{hexToRgb(#ea4242)};
  --color-red5-rgb: #{hexToRgb(#e11f1f)};
  --color-red6-rgb: #{hexToRgb(#ffc6c6)};
  --color-black0-payment: #383838;
  --color-black1-payment: #494949;
  --color-black2-payment: #3b4054;
  --color-gray0-payment: #f8fafba3;
  --color-gray1-payment: #d5d5d5;
  --color-gray2-payment: #828282;
  --color-gray3-payment: #949494;
  --color-gray4-payment: #686868;
  --color-gray5-payment: #585f7c;
  --color-gray6-payment: #f2f2f2;
  --color-gray7-payment: #fafafa;
  --color-black0-rgb: #{hexToRgb(#383838)};
  --color-black1-rgb: #{hexToRgb(#494949)};
  --color-black2-rgb: #{hexToRgb(#3b4054)};
  --color-gray0-rgb: #{hexToRgb(#c2c9d1)};
  --color-gray1-rgb: #{hexToRgb(#d5d5d5)};
  --color-gray2-rgb: #{hexToRgb(#828282)};
  --color-gray3-rgb: #{hexToRgb(#949494)};
  --color-gray4-rgb: #{hexToRgb(#686868)};
  --color-gray5-rgb: #{hexToRgb(#585f7c)};
  --color-gray6-rgb: #{hexToRgb(#f2f2f2)};
  --color-gray7-rgb: #{hexToRgb(#fafafa)};
  --width-modal-checkout-payment: 440px;
}

// =============================== Backgroud Color ====================================
.bg-white0-payment {
  background-color: var(--color-white0-payment);
}
[class*='!bg-white0-payment'] {
  background-color: var(--color-white0-payment) !important;
}
.bg-white1-payment {
  background-color: var(--color-white1-payment);
}
[class*='!bg-white1-payment'] {
  background-color: var(--color-white1-payment) !important;
}
.bg-blue0-payment {
  background-color: var(--color-blue0-payment);
}
[class*='!bg-blue0-payment'] {
  background-color: var(--color-blue0-payment) !important;
}
.bg-blue1-payment {
  background-color: var(--color-blue1-payment);
}
[class*='!bg-blue1-payment'] {
  background-color: var(--color-blue1-payment) !important;
}
.bg-blue2-payment {
  background-color: var(--color-blue2-payment);
}
[class*='!bg-blue2-payment'] {
  background-color: var(--color-blue2-payment) !important;
}
.bg-blue3-payment {
  background-color: var(--color-blue3-payment);
}
[class*='!bg-blue3-payment'] {
  background-color: var(--color-blue3-payment) !important;
}
.bg-blue4-payment {
  background-color: var(--color-blue4-payment);
}
[class*='!bg-blue4-payment'] {
  background-color: var(--color-blue4-payment) !important;
}
.bg-blue5-payment {
  background-color: var(--color-blue5-payment);
}
[class*='!bg-blue5-payment'] {
  background-color: var(--color-blue5-payment) !important;
}
.bg-yellow0-payment {
  background-color: var(--color-yellow0-payment);
}
[class*='!bg-yellow0-payment'] {
  background-color: var(--color-yellow0-payment) !important;
}
.bg-yellow1-payment {
  background-color: var(--color-yellow1-payment);
}
[class*='!bg-yellow1-payment'] {
  background-color: var(--color-yellow1-payment) !important;
}
.bg-yellow2-payment {
  background-color: var(--color-yellow2-payment);
}
[class*='!bg-yellow2-payment'] {
  background-color: var(--color-yellow2-payment) !important;
}
.bg-red0-payment {
  background-color: var(--color-red0-payment);
}
[class*='!bg-red0-payment'] {
  background-color: var(--color-red0-payment) !important;
}
.bg-red1-payment {
  background-color: var(--color-red1-payment);
}
[class*='!bg-red1-payment'] {
  background-color: var(--color-red1-payment) !important;
}
.bg-red2-payment {
  background-color: var(--color-red2-payment);
}
[class*='!bg-red2-payment'] {
  background-color: var(--color-red2-payment) !important;
}
.bg-red3-payment {
  background-color: var(--color-red3-payment);
}
[class*='!bg-red3-payment'] {
  background-color: var(--color-red3-payment) !important;
}
.bg-red4-payment {
  background-color: var(--color-red4-payment);
}
[class*='!bg-red4-payment'] {
  background-color: var(--color-red4-payment) !important;
}
.bg-red5-payment {
  background-color: var(--color-red5-payment);
}
[class*='!bg-red5-payment'] {
  background-color: var(--color-red5-payment) !important;
}
.bg-red6-payment {
  background-color: var(--color-red6-payment);
}
[class*='!bg-red6-payment'] {
  background-color: var(--color-red6-payment) !important;
}
.bg-black0-payment {
  background-color: var(--color-black0-payment);
}
[class*='!bg-black0-payment'] {
  background-color: var(--color-black0-payment) !important;
}
.bg-black1-payment {
  background-color: var(--color-black1-payment);
}
[class*='!bg-black1-payment'] {
  background-color: var(--color-black1-payment) !important;
}
.bg-black2-payment {
  background-color: var(--color-black2-payment);
}
[class*='!bg-black2-payment'] {
  background-color: var(--color-black2-payment) !important;
}
.bg-gray0-payment {
  background-color: var(--color-gray0-payment);
}
[class*='!bg-gray0-payment'] {
  background-color: var(--color-gray0-payment) !important;
}
.bg-gray1-payment {
  background-color: var(--color-gray1-payment);
}
[class*='!bg-gray1-payment'] {
  background-color: var(--color-gray1-payment) !important;
}
.bg-gray2-payment {
  background-color: var(--color-gray2-payment);
}
[class*='!bg-gray2-payment'] {
  background-color: var(--color-gray2-payment) !important;
}
.bg-gray3-payment {
  background-color: var(--color-gray3-payment);
}
[class*='!bg-gray3-payment'] {
  background-color: var(--color-gray3-payment) !important;
}
.bg-gray4-payment {
  background-color: var(--color-gray4-payment);
}
[class*='!bg-gray4-payment'] {
  background-color: var(--color-gray4-payment) !important;
}
.bg-gray5-payment {
  background-color: var(--color-gray5-payment);
}
[class*='!bg-gray5-payment'] {
  background-color: var(--color-gray5-payment) !important;
}
.bg-gray6-payment {
  background-color: var(--color-gray6-payment);
}
[class*='!bg-gray6-payment'] {
  background-color: var(--color-gray6-payment) !important;
}
.bg-gray7-payment {
  background-color: var(--color-gray7-payment);
}
[class*='!bg-gray7-payment'] {
  background-color: var(--color-gray7-payment) !important;
}
.bg-red0-stripes {
  background-color: rgba(var(--color-red0-rgb), 0.1);
  background-image: linear-gradient(
    135deg,
    var(--color-red0-payment) 0%,
    transparent 0,
    transparent 50%,
    var(--color-red0-payment) 0,
    var(--color-red0-payment) 55%,
    transparent 0,
    transparent
  );
  background-size: 7.07px 7.07px;
}
[class*='!bg-red0-stripes'] {
  background-color: rgba(var(--color-red0-rgb), 0.5) !important;
  background-image: linear-gradient(
    135deg,
    var(--color-red0-payment) 0%,
    transparent 0,
    transparent 50%,
    var(--color-red0-payment) 0,
    var(--color-red0-payment) 55%,
    transparent 0,
    transparent
  );
  background-size: 7.07px 7.07px;
}
.bg-red1-stripes {
  background-color: rgba(var(--color-red1-rgb), 0.1);
  background-image: linear-gradient(
    135deg,
    var(--color-red1-payment) 0%,
    transparent 0,
    transparent 50%,
    var(--color-red1-payment) 0,
    var(--color-red1-payment) 55%,
    transparent 0,
    transparent
  );
  background-size: 7.07px 7.07px;
}
[class*='!bg-red1-stripes'] {
  background-color: rgba(var(--color-red1-rgb), 0.5) !important;
  background-image: linear-gradient(
    135deg,
    var(--color-red1-payment) 0%,
    transparent 0,
    transparent 50%,
    var(--color-red1-payment) 0,
    var(--color-red1-payment) 55%,
    transparent 0,
    transparent
  );
  background-size: 7.07px 7.07px;
}
.bg-red2-stripes {
  background-color: rgba(var(--color-red2-rgb), 0.1);
  background-image: linear-gradient(
    135deg,
    var(--color-red2-payment) 0%,
    transparent 0,
    transparent 50%,
    var(--color-red2-payment) 0,
    var(--color-red2-payment) 55%,
    transparent 0,
    transparent
  );
  background-size: 7.07px 7.07px;
}
[class*='!bg-red2-stripes'] {
  background-color: rgba(var(--color-red2-rgb), 0.5) !important;
  background-image: linear-gradient(
    135deg,
    var(--color-red2-payment) 0%,
    transparent 0,
    transparent 50%,
    var(--color-red2-payment) 0,
    var(--color-red2-payment) 55%,
    transparent 0,
    transparent
  );
  background-size: 7.07px 7.07px;
}
.bg-red3-stripes {
  background-color: rgba(var(--color-red3-rgb), 0.1);
  background-image: linear-gradient(
    135deg,
    var(--color-red3-payment) 0%,
    transparent 0,
    transparent 50%,
    var(--color-red3-payment) 0,
    var(--color-red3-payment) 55%,
    transparent 0,
    transparent
  );
  background-size: 7.07px 7.07px;
}
[class*='!bg-red3-stripes'] {
  background-color: rgba(var(--color-red3-rgb), 0.5) !important;
  background-image: linear-gradient(
    135deg,
    var(--color-red3-payment) 0%,
    transparent 0,
    transparent 50%,
    var(--color-red3-payment) 0,
    var(--color-red3-payment) 55%,
    transparent 0,
    transparent
  );
  background-size: 7.07px 7.07px;
}
.bg-red4-stripes {
  background-color: rgba(var(--color-red4-rgb), 0.1);
  background-image: linear-gradient(
    135deg,
    var(--color-red4-payment) 0%,
    transparent 0,
    transparent 50%,
    var(--color-red4-payment) 0,
    var(--color-red4-payment) 55%,
    transparent 0,
    transparent
  );
  background-size: 7.07px 7.07px;
}
[class*='!bg-red4-stripes'] {
  background-color: rgba(var(--color-red4-rgb), 0.5) !important;
  background-image: linear-gradient(
    135deg,
    var(--color-red4-payment) 0%,
    transparent 0,
    transparent 50%,
    var(--color-red4-payment) 0,
    var(--color-red4-payment) 55%,
    transparent 0,
    transparent
  );
  background-size: 7.07px 7.07px;
}
.bg-red5-stripes {
  background-color: rgba(var(--color-red5-rgb), 0.1);
  background-image: linear-gradient(
    135deg,
    var(--color-red5-payment) 0%,
    transparent 0,
    transparent 50%,
    var(--color-red5-payment) 0,
    var(--color-red5-payment) 55%,
    transparent 0,
    transparent
  );
  background-size: 7.07px 7.07px;
}
[class*='!bg-red5-stripes'] {
  background-color: rgba(var(--color-red5-rgb), 0.5) !important;
  background-image: linear-gradient(
    135deg,
    var(--color-red5-payment) 0%,
    transparent 0,
    transparent 50%,
    var(--color-red5-payment) 0,
    var(--color-red5-payment) 55%,
    transparent 0,
    transparent
  );
  background-size: 7.07px 7.07px;
}
.bg-red6-stripes {
  background-color: rgba(var(--color-red6-rgb), 0.1);
  background-image: linear-gradient(
    135deg,
    var(--color-red6-payment) 0%,
    transparent 0,
    transparent 50%,
    var(--color-red6-payment) 0,
    var(--color-red6-payment) 55%,
    transparent 0,
    transparent
  );
  background-size: 7.07px 7.07px;
}
[class*='!bg-red6-stripes'] {
  background-color: rgba(var(--color-red6-rgb), 0.5) !important;
  background-image: linear-gradient(
    135deg,
    var(--color-red6-payment) 0%,
    transparent 0,
    transparent 50%,
    var(--color-red6-payment) 0,
    var(--color-red6-payment) 55%,
    transparent 0,
    transparent
  );
  background-size: 7.07px 7.07px;
}
.bg-gray0-stripes {
  background-color: rgba(var(--color-gray0-rgb), 0.1);
  background-image: linear-gradient(
    135deg,
    var(--color-gray0-payment) 0%,
    transparent 0,
    transparent 50%,
    var(--color-gray0-payment) 0,
    var(--color-gray0-payment) 55%,
    transparent 0,
    transparent
  );
  background-size: 7.07px 7.07px;
}
[class*='!bg-gray0-stripes'] {
  background-color: rgba(var(--color-gray0-rgb), 0.5) !important;
  background-image: linear-gradient(
    135deg,
    var(--color-gray0-payment) 0%,
    transparent 0,
    transparent 50%,
    var(--color-gray0-payment) 0,
    var(--color-gray0-payment) 55%,
    transparent 0,
    transparent
  );
  background-size: 7.07px 7.07px;
}

// =============================== Text Color ====================================
.text-white0-payment {
  color: var(--color-white0-payment);
}
[class*='!text-white0-payment'] {
  color: var(--color-white0-payment) !important;
}
.text-white1-payment {
  color: var(--color-white1-payment);
}
[class*='!text-white1-payment'] {
  color: var(--color-white1-payment) !important;
}
.text-blue0-payment {
  color: var(--color-blue0-payment);
}
[class*='!text-blue0-payment'] {
  color: var(--color-blue0-payment) !important;
}
.text-blue1-payment {
  color: var(--color-blue1-payment);
}
[class*='!text-blue1-payment'] {
  color: var(--color-blue1-payment) !important;
}
.text-blue2-payment {
  color: var(--color-blue2-payment);
}
[class*='!text-blue2-payment'] {
  color: var(--color-blue2-payment) !important;
}
.text-blue3-payment {
  color: var(--color-blue3-payment);
}
[class*='!text-blue3-payment'] {
  color: var(--color-blue3-payment) !important;
}
.text-blue4-payment {
  color: var(--color-blue4-payment);
}
[class*='!text-blue4-payment'] {
  color: var(--color-blue4-payment) !important;
}
.text-blue5-payment {
  color: var(--color-blue5-payment);
}
[class*='!text-blue5-payment'] {
  color: var(--color-blue5-payment) !important;
}
.text-yellow0-payment {
  color: var(--color-yellow0-payment);
}
[class*='!text-yellow0-payment'] {
  color: var(--color-yellow0-payment) !important;
}
.text-yellow1-payment {
  color: var(--color-yellow1-payment);
}
[class*='!text-yellow1-payment'] {
  color: var(--color-yellow1-payment) !important;
}
.text-yellow2-payment {
  color: var(--color-yellow2-payment);
}
[class*='!text-yellow2-payment'] {
  color: var(--color-yellow2-payment) !important;
}
.text-red0-payment {
  color: var(--color-red0-payment);
}
[class*='!text-red0-payment'] {
  color: var(--color-red0-payment) !important;
}
.text-red1-payment {
  color: var(--color-red1-payment);
}
[class*='!text-red1-payment'] {
  color: var(--color-red1-payment) !important;
}
.text-red2-payment {
  color: var(--color-red2-payment);
}
[class*='!text-red2-payment'] {
  color: var(--color-red2-payment) !important;
}
.text-red3-payment {
  color: var(--color-red3-payment);
}
[class*='!text-red3-payment'] {
  color: var(--color-red3-payment) !important;
}
.text-red4-payment {
  color: var(--color-red4-payment);
}
[class*='!text-red4-payment'] {
  color: var(--color-red4-payment) !important;
}
.text-red5-payment {
  color: var(--color-red5-payment);
}
[class*='!text-red5-payment'] {
  color: var(--color-red5-payment) !important;
}
.text-black0-payment {
  color: var(--color-black0-payment);
}
[class*='!text-black0-payment'] {
  color: var(--color-black0-payment) !important;
}
.text-black1-payment {
  color: var(--color-black1-payment);
}
[class*='!text-black1-payment'] {
  color: var(--color-black1-payment) !important;
}
.text-black2-payment {
  color: var(--color-black2-payment);
}
[class*='!text-black2-payment'] {
  color: var(--color-black2-payment) !important;
}
.text-gray0-payment {
  color: var(--color-gray0-payment);
}
[class*='!text-gray0-payment'] {
  color: var(--color-gray0-payment) !important;
}
.text-gray1-payment {
  color: var(--color-gray1-payment);
}
[class*='!text-gray1-payment'] {
  color: var(--color-gray1-payment) !important;
}
.text-gray2-payment {
  color: var(--color-gray2-payment);
}
[class*='!text-gray2-payment'] {
  color: var(--color-gray2-payment) !important;
}
.text-gray3-payment {
  color: var(--color-gray3-payment);
}
[class*='!text-gray3-payment'] {
  color: var(--color-gray3-payment) !important;
}
.text-gray4-payment {
  color: var(--color-gray4-payment);
}
[class*='!text-gray4-payment'] {
  color: var(--color-gray4-payment) !important;
}
.text-gray5-payment {
  color: var(--color-gray5-payment);
}
[class*='!text-gray5-payment'] {
  color: var(--color-gray5-payment) !important;
}
.text-gray6-payment {
  color: var(--color-gray6-payment);
}
[class*='!text-gray6-payment'] {
  color: var(--color-gray6-payment) !important;
}
.text-gray7-payment {
  color: var(--color-gray7-payment);
}
[class*='!text-gray7-payment'] {
  color: var(--color-gray7-payment) !important;
}

// =============================== Border Color ====================================
.border-white0-payment {
  border-color: var(--color-white0-payment);
}
[class*='!border-white0-payment'] {
  border-color: var(--color-white0-payment) !important;
}
.border-white1-payment {
  border-color: var(--color-white1-payment);
}
[class*='!border-white1-payment'] {
  border-color: var(--color-white1-payment) !important;
}
.border-blue0-payment {
  border-color: var(--color-blue0-payment);
}
[class*='!border-blue0-payment'] {
  border-color: var(--color-blue0-payment) !important;
}
.border-blue1-payment {
  border-color: var(--color-blue1-payment);
}
[class*='!border-blue1-payment'] {
  border-color: var(--color-blue1-payment) !important;
}
.border-blue2-payment {
  border-color: var(--color-blue2-payment);
}
[class*='!border-blue2-payment'] {
  border-color: var(--color-blue2-payment) !important;
}
.border-blue3-payment {
  border-color: var(--color-blue3-payment);
}
[class*='!border-blue3-payment'] {
  border-color: var(--color-blue3-payment) !important;
}
.border-blue4-payment {
  border-color: var(--color-blue4-payment);
}
[class*='!border-blue4-payment'] {
  border-color: var(--color-blue4-payment) !important;
}
.border-blue5-payment {
  border-color: var(--color-blue5-payment);
}
[class*='!border-blue5-payment'] {
  border-color: var(--color-blue5-payment) !important;
}
.border-yellow0-payment {
  border-color: var(--color-yellow0-payment);
}
[class*='!border-yellow0-payment'] {
  border-color: var(--color-yellow0-payment) !important;
}
.border-yellow1-payment {
  border-color: var(--color-yellow1-payment);
}
[class*='!border-yellow1-payment'] {
  border-color: var(--color-yellow1-payment) !important;
}
.border-yellow2-payment {
  border-color: var(--color-yellow2-payment);
}
[class*='!border-yellow2-payment'] {
  border-color: var(--color-yellow2-payment) !important;
}
.border-red0-payment {
  border-color: var(--color-red0-payment);
}
[class*='!border-red0-payment'] {
  border-color: var(--color-red0-payment) !important;
}
.border-red1-payment {
  border-color: var(--color-red1-payment);
}
[class*='!border-red1-payment'] {
  border-color: var(--color-red1-payment) !important;
}
.border-red2-payment {
  border-color: var(--color-red2-payment);
}
[class*='!border-red2-payment'] {
  border-color: var(--color-red2-payment) !important;
}
.border-red3-payment {
  border-color: var(--color-red3-payment);
}
[class*='!border-red3-payment'] {
  border-color: var(--color-red3-payment) !important;
}
.border-red4-payment {
  border-color: var(--color-red4-payment);
}
[class*='!border-red4-payment'] {
  border-color: var(--color-red4-payment) !important;
}
.border-red5-payment {
  border-color: var(--color-red5-payment);
}
[class*='!border-red5-payment'] {
  border-color: var(--color-red5-payment) !important;
}
.border-black0-payment {
  border-color: var(--color-black0-payment);
}
[class*='!border-black0-payment'] {
  border-color: var(--color-black0-payment) !important;
}
.border-black1-payment {
  border-color: var(--color-black1-payment);
}
[class*='!border-black1-payment'] {
  border-color: var(--color-black1-payment) !important;
}
.border-black2-payment {
  border-color: var(--color-black2-payment);
}
[class*='!border-black2-payment'] {
  border-color: var(--color-black2-payment) !important;
}
.border-gray0-payment {
  border-color: var(--color-gray0-payment);
}
[class*='!border-gray0-payment'] {
  border-color: var(--color-gray0-payment) !important;
}
.border-gray1-payment {
  border-color: var(--color-gray1-payment);
}
[class*='!border-gray1-payment'] {
  border-color: var(--color-gray1-payment) !important;
}
.border-gray2-payment {
  border-color: var(--color-gray2-payment);
}
[class*='!border-gray2-payment'] {
  border-color: var(--color-gray2-payment) !important;
}
.border-gray3-payment {
  border-color: var(--color-gray3-payment);
}
[class*='!border-gray3-payment'] {
  border-color: var(--color-gray3-payment) !important;
}
.border-gray4-payment {
  border-color: var(--color-gray4-payment);
}
[class*='!border-gray4-payment'] {
  border-color: var(--color-gray4-payment) !important;
}
.border-gray5-payment {
  border-color: var(--color-gray5-payment);
}
[class*='!border-gray5-payment'] {
  border-color: var(--color-gray5-payment) !important;
}
.border-gray6-payment {
  border-color: var(--color-gray6-payment);
}
[class*='!border-gray6-payment'] {
  border-color: var(--color-gray6-payment) !important;
}
.border-gray7-payment {
  border-color: var(--color-gray7-payment);
}
[class*='!border-gray7-payment'] {
  border-color: var(--color-gray7-payment) !important;
}
