.vmMobileFilterHideScroll ::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
  display: none;
}

.vmMobileFilterHideScroll {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
