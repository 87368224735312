#patient-info {
//   background-color: #edf3ff;
  transition: all 0.5s;
  // position: fixed;
  margin: 1rem;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;

  button {
    transition: all 0.5s;
  }

  div {
    background-color: #fff;
    p {
      transition: all 0.5s;
    }
  }
}
