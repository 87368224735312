/* Scroll bar styling */
/* width */
::-webkit-scrollbar {
  background: rgba(255, 255, 255, 0);
  width: 0px;
  height: 0px;
  border-radius: 0px;

  &:hover {
    background: rgba(255, 255, 255, 0);
  }
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  width: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0);
  border-radius: 10px;
  width: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0);
  width: 0px;
}

/* End Scroll bar styling */
/* Scroll bar styling */
.useScrollBar {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #666;
  }
}
/* End Scroll bar styling */

/* Select arrow styling */
select {
  --sizeIco: 90% / 15%;

  &[class*='-px'] {
    padding-right: 2.5px !important;
    --sizeIco: 90% / 12%;
  }

  &[class*='p-0.5'],
  &[class*='pr-0.5'],
  &[class*='px-0.5'] {
    padding-right: 0.25rem !important;
    --sizeIco: 90% / 12%;
  }

  &[class*='px-4'] {
    padding-right: 2.5rem !important;
    --sizeIco: 90% / 12%;
  }

  /* Remove default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* Add ico for change default */
  // background: url(assets/favicon.ico) var(--sizeIco) no-repeat #FFF;
}

select::-ms-expand {
  /* Remove default arrow in Internet Explorer 10 and 11 */
  display: none;
}

/* Target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width: 0\0) {
  select {
    background: none\9;
    padding: 5px\9;
  }
}

/* Text break word */
.text-break-word {
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
}
/* Text break word important */
[class*='!text-break-word'] {
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
  text-overflow: ellipsis !important;
}

@layer components {
  // =================== VmHeader ==================
  .VmHeader {
    @apply flex flex-row;

    & > button {
      @apply ring-0 ring-transparent flex self-center justify-center items-center overflow-hidden;
      background-color: var(--color-white0-payment) !important;
      text-align: -webkit-center;
      padding: 0px;
      height: 40px;
      width: 40px;

      &[class*='vmheader-btn-circle'] {
        @apply rounded-full;
      }

      &[class*='bg-blue-clasic'] {
        background: var(--color-blue1-payment) !important;
        color: var(--color-blue1-payment) !important;

        svg {
          stroke-width: 2px;
          stroke: var(--color-white0-payment) !important;
          fill: var(--color-white0-payment) !important;
        }
      }
      &[class*='bg-blue-gradient'] {
        background: linear-gradient(
          160.79deg,
          var(--color-blue1-payment) 0.31%,
          var(--color-blue0-payment) 98.99%
        );
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.24);
        color: var(--color-blue1-payment);

        svg {
          stroke-width: 2px;
          stroke: var(--color-white0-payment) !important;
          fill: var(--color-white0-payment) !important;
        }
      }

      & > .bg-btn-header {
        @apply flex justify-center items-center overflow-hidden w-full h-full;

        svg {
          stroke-width: 2px;
          stroke: url(#MyBgGradient);
          fill: url(#MyBgGradient);

          #MyBgGradient {
            stop {
              stop-color: var(--color-blue1-payment);

              &:last-child {
                stop-color: var(--color-blue0-payment);
              }
            }
          }
        }
      }
    }

    & > .title {
      @apply pl-5;

      .name {
        @apply text-white;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        align-items: flex-end;
        letter-spacing: 0.4px;
        min-width: 100px;

        &[class*='bg-blue-clasic'] {
          color: var(--color-blue1-payment) !important;
        }
        &[class*='bg-blue-gradient'] {
          color: var(--color-blue1-payment) !important;
        }
      }

      .type {
        @apply text-white relative;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        align-items: flex-end;
        letter-spacing: 0.4px;
        min-width: 100px;

        &[class*='bg-blue-gradient'] {
          color: var(--color-blue1-payment) !important;
        }
      }
    }
  }

  // =================== ModalSearch ==================
  .ModalSearch {
    height: auto;
    width: inherit;
    max-height: 90%;
    max-width: 90%;
    position: initial !important;
    background: var(--color-white1-payment);
    box-shadow: 0px 22px 70px 4px rgba(0, 0, 0, 0.07);

    .FormSearch {
      padding: 0 !important;

      .EheadSearch {
        @apply flex flex-row justify-between sticky top-0;
        padding-inline: 20px;
        padding-top: 31px;
        padding-bottom: 27px;
        background: linear-gradient(
          92.84deg,
          var(--color-blue1-payment) 0%,
          var(--color-blue0-payment) 100%
        );
        box-shadow: 0px 22px 70px rgba(0, 0, 0, 0.07);

        .EtitleSearch {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          display: flex;
          align-items: center;
          letter-spacing: 0.4px;
          color: var(--color-white0-payment);
        }

        .EbtncloseSearch {
          @apply flex items-center justify-center;
          width: 40px;
          height: 40px;
          background: var(--color-white0-payment);
          border-radius: 10px;
        }
      }

      .EbodySearch {
      }

      .EbodySearch > :first-child:not([class*='ProgressFullScreen']) {
        display: flex;
        position: unset;
        flex-direction: column;
      }

      .EbodySearch > :first-child:not([class*='ProgressFullScreen']) > * {
        width: -webkit-fill-available !important;
        left: 5%;
        right: 5%;
      }

      .EbodySearch
        > :last-child:not([class*='ProgressFullScreen'])
        > :first-child {
        left: 5%;
        right: 5%;
        bottom: 5%;
      }

      .EfootSearch {
        @apply flex flex-row justify-between sticky bottom-0;
        background: var(--color-white1-payment);
      }
    }
  }

  // =================== FormCheckoutPayment ==================
  .ModalCheckoutPayment {
    height: auto;
    max-height: 90%;
    background: var(--color-white1-payment);
    box-shadow: 0px 22px 70px 4px rgba(0, 0, 0, 0.07);
    width: var(--width-modal-checkout-payment);
    position: initial !important;

    .FormCheckoutPayment {
      padding: 0 !important;

      .EheadCheckoutPayment {
        @apply flex flex-row justify-between sticky top-0;
        padding-inline: 20px;
        padding-top: 31px;
        padding-bottom: 27px;
        background: linear-gradient(
          92.84deg,
          var(--color-blue1-payment) 0%,
          var(--color-blue0-payment) 100%
        );
        box-shadow: 0px 22px 70px rgba(0, 0, 0, 0.07);

        .EtitleCheckoutPayment {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          display: flex;
          align-items: center;
          letter-spacing: 0.4px;
          color: var(--color-white0-payment);
        }

        .EbtncloseCheckoutPayment {
          @apply flex items-center justify-center;
          width: 40px;
          height: 40px;
          background: var(--color-white0-payment);
          border-radius: 10px;
        }
      }

      .EbodyCheckoutPayment {
        // width: var(--width-modal-checkout-payment);

        .EsectionCheckoutPayment {
          @apply flex flex-row m-5;
        }

        .EtotalcostCheckoutPayment,
        .EtotalchangeCheckoutPayment {
          flex: 1;

          :first-child {
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            color: var(--color-black0-payment);
          }

          :last-child {
            font-weight: 700;
            font-size: 36px;
            color: var(--color-blue1-payment);
          }
        }

        .EoptpaymentCheckoutPayment {
          @apply m-5;

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: var(--color-black0-payment);
          }

          & > :last-child {
            display: -webkit-inline-box;
            width: -webkit-fill-available;
            overflow-x: scroll;

            button {
              @apply ring-0 ring-transparent flex flex-row items-center shadow-md;
              background-color: var(--color-gray1-payment);
              padding: 15px !important;
              margin-block: 3px;
              margin-right: 20px;
              color: var(--color-gray2-payment);

              svg {
                stroke: var(--color-gray2-payment);
                margin-right: 15px;
              }

              &[class*='active'] {
                background-color: var(--color-blue0-payment);
                color: var(--color-white0-payment);

                svg {
                  stroke: var(--color-white0-payment);
                }
              }
            }
          }
        }

        .EoptdiscCheckoutPayment {
          .EtitlediscCheckoutPayment {
            @apply flex flex-row m-5;

            :last-child,
            :first-child {
              font-size: 14px;
              line-height: 16px;
            }

            :first-child {
              font-weight: 400;
              color: var(--color-black0-payment);
            }

            :last-child {
              font-weight: 700;
              padding-left: 10px;
              color: var(--color-blue1-payment);
            }
          }
        }

        .EoptdiscCheckoutPayment > .EcontendiscCheckoutPayment {
          @apply pb-3;
          background: var(--color-blue2-payment);
          box-shadow: 0px 22px 70px 4px rgba(0, 0, 0, 0.07);
        }

        .EcontendiscCheckoutPayment {
          @apply flex flex-row px-5 pb-5;

          .EprctdiscCheckoutPayment,
          .EtotaldiscCheckoutPayment {
            @apply flex flex-col;
            // flex: 1;
            width: min-content;

            p {
              font-weight: 500;
              font-size: 14px;
              line-height: 37px;
              color: var(--color-black0-payment);
            }

            .input-group {
              @apply shadow-md;
              border-radius: 10px;
              padding: 15px;

              input {
                color: var(--color-black1-payment);

                &[type='text'] {
                  padding-inline: 10px !important;
                }
              }
            }
          }

          .EprctdiscCheckoutPayment {
            @apply mr-5;
            flex: 1 1 100%;

            .input-group {
              input {
                &[type='text'] {
                  padding-left: 10px !important;
                  padding-right: 0px !important;
                }
              }

              svg {
                height: 22px;
                width: 34px;
              }
            }
          }

          .EtotaldiscCheckoutPayment {
            flex: 1 1 200%;

            .input-group {
              svg {
                height: 22px;
                width: 26px;
              }
            }
          }
        }

        .EtotaldownpaymentCheckoutPayment,
        .EduedateCheckoutPayment,
        .EnamecardCheckoutPayment,
        .EtotalcashCheckoutPayment {
          @apply m-5;

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 30px;
            color: var(--color-black0-payment);
          }

          .input-group {
            @apply shadow-md;
            border-radius: 10px;
            padding: 15px;

            input {
              color: var(--color-black1-payment);

              &[type='text'] {
                padding-inline: 10px !important;
              }
            }
          }
        }

        .EsugestioncostCheckoutPayment {
          @apply m-5;
          display: flex;
          overflow-x: scroll;

          button {
            @apply ring-0 ring-transparent;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            padding-block: 8px;
            padding-inline: 30px;
            margin-right: 20px;
            background-color: var(--color-blue0-payment);
          }
        }

        .EcontentcardCheckoutPayment {
          @apply flex flex-row m-5;

          p {
            font-weight: 500;
            font-size: 14px;
            line-height: 37px;
            color: var(--color-black0-payment);
          }

          .input-group {
            @apply shadow-md;
            border-radius: 10px;
            padding: 15px;

            input {
              color: var(--color-black1-payment);

              &[type='text'] {
                padding-inline: 10px !important;
              }
            }
          }

          .EtotalcardCheckoutPayment {
            flex: 1 1 200%;
            width: max-content;

            .input-group {
              svg {
                height: 22px;
                width: 26px;
              }
            }
          }

          .EnamebankCheckoutPayment {
            @apply ml-5;
            flex: 1 1 100%;

            .input-group {
              svg {
                height: 22px;
                width: 34px;
              }
            }
          }
        }

        .EdeleteCheckoutPayment {
          text-align: center;
          margin-inline: 20px;
          margin-top: 45px;
          margin-bottom: 65px;

          :first-child {
            font-weight: 400;
            font-size: 16px;
            color: var(--color-black0-payment);
          }

          :last-child {
            font-weight: 700;
            font-size: 20px;
            color: var(--color-blue1-payment);
          }
        }
      }

      .EfootCheckoutPayment {
        @apply flex flex-row justify-between sticky bottom-0;
        background: var(--color-white1-payment);

        .EbtndeleteCheckoutPayment,
        .EbtnsubmitCheckoutPayment {
          @apply shadow-md;
          flex: 1;
          border-radius: 25px;
          font-weight: 700;
          font-size: 20px;
          line-height: 16px;
          padding-block: 20px;
          margin-right: 20px;
          margin-bottom: 15px;
          color: var(--color-white0-payment);

          &:first-child {
            margin-inline: 20px;
          }
        }

        [class*='submit'] {
          background: linear-gradient(
            91.38deg,
            var(--color-yellow1-payment) 0%,
            var(--color-yellow0-payment) 100%
          );
        }

        [class*='delete'] {
          background: linear-gradient(
            91.38deg,
            var(--color-red2-payment) 0%,
            var(--color-red3-payment) 100%
          );
        }
      }
    }
  }

  // =================== FormCashier ==================
  .FormCashierWrapper {
    @apply fixed inset-0 flex flex-col;
    background-color: #eaeaea;
  }

  .FormCashier {
    @apply flex flex-1 py-5 pl-5;
    flex-direction: row-reverse;

    *[data-placeholder='true'] {
      border-radius: 10px !important;
    }

    &[class*='phone'] {
      @apply p-5;
    }

    overflow-y: scroll;

    .BgTopFormCashier {
      @apply absolute inset-0;
      height: 14.4rem;
      background: linear-gradient(
        93.19deg,
        var(--color-blue1-payment) 0%,
        var(--color-blue0-payment) 100%
      );
      border-radius: 0px 0px 10px 10px;
      z-index: -1;

      &[class*='phone'] {
        border-radius: 0px;
        height: 16rem;
      }

      svg {
        position: absolute;
        top: 112px;
        height: -webkit-fill-available;
        width: -webkit-fill-available;
      }
    }

    .VmLeftCashier {
      @apply flex flex-col;
      flex: 1;
      height: -webkit-fill-available;
      min-width: 65%;

      &[class*='phone'] {
        width: -webkit-fill-available;
      }

      .head {
        @apply flex flex-row;
        // margin-bottom: 30px;

        &[class*='phone'] {
          margin-bottom: 0px;
        }

        button {
          background-color: var(--color-white0-payment);
          text-align: -webkit-center;
          padding: 10px;
          height: 40px;
          width: 40px;
        }

        .title {
          @apply pl-5;

          .name {
            @apply text-white;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            align-items: flex-end;
            letter-spacing: 0.4px;
            min-width: 100px;
          }

          .type {
            @apply text-white relative;
            top: -5px;
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 32px;
            align-items: flex-end;
            letter-spacing: 0.4px;
            min-width: 100px;

            &[data-placeholder='true'] {
              top: 0px;
            }

            &[class*='phone'] {
              font-size: 16px;
            }
          }
        }
      }

      .info-user {
        @apply flex flex-row items-end;
        max-height: 16%;

        &[class*='phone'] {
          overflow: scroll;
          max-height: unset;
        }

        .wrap-input-user-2 {
          @apply mr-8;
          flex: 2;

          .input-group {
            width: fit-content !important;
          }
        }

        .wrap-input-user {
          @apply mr-4;
          flex: 1;
        }

        [class*='f0.3'] {
          margin-bottom: 10px;

          input {
            &[type='date'],
            &[type='button'] {
              height: 43px;
              font-size: 0.625rem;
            }
          }
        }

        .wrap-input-user-2,
        .wrap-input-user {
          margin-bottom: 10px;

          &:last-child {
            margin-right: 0;
          }

          &[class*='phone'] {
            min-width: 155px;

            input {
              &[type='date'],
              &[type='button'] {
                height: 43px;
                font-size: 0.625rem;
              }
            }

            .input-group {
              input {
                &[type='text'] {
                  height: 43px;
                }
              }
            }
          }
        }

        p {
          font-weight: 400;
          font-size: 0.75rem;
          // line-height: 34px;
          white-space: nowrap;
          display: flex;
          align-items: flex-end;
          text-align: center;
          letter-spacing: 0.4px;
          color: var(--color-white0-payment);
        }

        .input-group * {
          &:focus,
          &:hover,
          &:active {
            @apply outline-none ring-1 ring-transparent ring-offset-0;
          }
        }

        input *,
        .input-group {
          border-radius: 10px;
          overflow: hidden;
        }

        input {
          &[type='date'],
          &[type='button'] {
            height: 2rem;
          }
        }

        .input-group {
          @apply flex-1;

          svg {
            --margin-icon-input-group: 10px;
            height: 25px;
            width: 25px;

            &:first-child {
              margin-inline: var(--margin-icon-input-group);
            }

            &:last-child {
              margin-inline: var(--margin-icon-input-group);
            }
          }

          input {
            font-size: 0.75rem;

            &[type='text'] {
              padding: 2px;
              height: 2rem;
            }
          }

          box-shadow: 0px 22px 70px 4px rgba(0, 0, 0, 0.07);
          border-radius: 10px;
        }
      }

      .search-bar {
        @apply flex flex-row items-end;
        margin-top: 0.5rem;

        &[class*='phone'] {
          margin-top: 10px;

          input {
            &[type='date'],
            &[type='button'] {
              height: 3rem;
            }
          }

          .input-group {
            input {
              &[type='text'] {
                height: 3rem;
              }
            }
          }
        }

        .input-group * {
          &:focus,
          &:hover,
          &:active {
            @apply outline-none ring-1 ring-transparent ring-offset-0;
          }
        }

        .input-group {
          --margin-icon-input-group: 15px;
          background: #fdfdfd;
          box-shadow: 0px 8px 24px rgba(187, 194, 201, 0.2);
          border-radius: 50px;

          input {
            background: #fdfdfd;
            height: 2.7rem;
            padding: 3px;

            &[aria-label='clearsearchcost'] {
              margin-inline: var(--margin-icon-input-group);
              font-weight: 800;
              font-size: 12px;
            }
          }

          svg {
            height: 1.3rem;
            width: 1.3rem;

            &:first-child {
              margin-inline: var(--margin-icon-input-group);
            }

            &:last-child {
              margin-inline: var(--margin-icon-input-group);
            }
          }
        }
      }

      .table-search {
        @apply mt-5 p-5;
        background: var(--color-white0-payment);
        border-radius: 20px;
        // height: -webkit-fill-available;
        // overflow: hidden;
        overflow-y: scroll;
        flex: 1;

        [class^='container-tabel'] {
          // height: -webkit-fill-available;
        }

        table {
          width: -webkit-fill-available;

          thead {
            background-color: var(--color-white0-payment);
            position: sticky;
            top: -1px;

            tr {
              th {
                :first-child {
                  color: rgba(0, 0, 0, 0.5);
                  justify-content: center;
                  font-weight: 400;
                  font-size: 0.9375rem;
                  line-height: 16px;
                  white-space: nowrap;
                  display: flex;
                  align-items: center;
                  text-align: center;
                  padding-block: 10px;
                  border-bottom: 1px solid var(--color-gray0-payment);
                }
              }
            }
          }

          tbody {
            // top: 10px;

            tr {
              line-height: 48px;
              border-bottom: 1px solid var(--color-gray0-payment);

              td {
                // height: 48px;

                &:first-child,
                &:last-child {
                  text-align: -webkit-center;
                }

                &.name-item {
                  font-weight: 500;
                  font-size: 16px;
                  color: var(--color-blue1-payment);
                }
              }
            }
          }
        }
      }

      .PreViewSearch {
        @apply flex;
        color: var(--color-black0-payment);
        margin-top: 23px;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 12px;

        :last-child {
          color: var(--color-blue1-payment);
          padding-left: 10px;
        }
      }

      .SearchPhone {
        width: 100%;

        .search-item-phone {
          @apply flex flex-row;
          padding: 12px;
          margin-bottom: 10px;
          background: var(--color-white0-payment);
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04),
            0px 8px 16px rgba(0, 0, 0, 0.08);
          border-radius: 10px;
          justify-content: space-between;

          :last-child {
            @apply flex items-center;
          }

          .name-item {
            font-weight: 500;
            font-size: 11px;
            color: var(--color-gray4-payment);
          }

          .price-item {
            font-weight: 600;
            font-size: 14px;
            color: var(--color-black0-payment);
          }

          .disc-item {
            font-weight: 500;
            font-size: 12px;
            color: var(--color-red0-payment);
          }
        }
      }
    }
  }

  // =================== VmCartItem ==================
  .VmCartItem {
    @apply p-5 m-5 rounded-[10px] bg-white;
    flex: 0.33;
    // max-width: 400px;
    --shadow-cart-item: 0px 73px 20px rgba(230, 230, 230, 0.01),
      0px 47px 19px rgba(230, 230, 230, 0.1),
      0px 26px 16px rgba(230, 230, 230, 0.35),
      0px 12px 12px rgba(230, 230, 230, 0.6),
      0px 3px 6px rgba(230, 230, 230, 0.69),
      0px 0px 0px rgba(230, 230, 230, 0.7);
    box-shadow: var(--shadow-cart-item);

    &[class*='phone'] {
      max-width: unset;

      &:last-child {
        margin-bottom: 150px;
      }
    }

    .VmCartItemHead {
      @apply flex flex-row justify-between mb-2;

      *.wrap-name {
        @apply mr-auto pr-2 flex-1;
        max-width: 75%;
        overflow: hidden;

        span {
          overflow: hidden;
          word-wrap: break-word;
          text-overflow: ellipsis;

          overflow-wrap: break-word;
          text-overflow: ellipsis;
          // line-break: anywhere;
          max-inline-size: fit-content;
          -webkit-line-clamp: 2; // <- you can change rows number
          -webkit-box-orient: vertical;
        }
      }

      *.name {
        font-weight: 700;
        color: var(--color-black0-payment);
        font-size: 16px;
      }

      *.change,
      *.delete {
        @apply flex items-center;
        border-radius: 5px;
        border-width: 1px;
        font-size: 12px;
        padding-inline: 7px;
        padding-block: 7px;
      }

      *.delete {
        border-color: var(--color-red0-payment) !important;
        color: var(--color-red0-payment);
        margin-left: 5px;
      }

      *.change {
        border-color: var(--color-blue0-payment) !important;
        color: var(--color-blue0-payment);
        margin-right: 5px;
      }
    }

    .VmCartItemFoot {
      @apply flex flex-row justify-between;

      *.price {
        font-weight: 400;
        color: var(--color-black0-payment);
        font-size: 20px;
      }

      *.disc {
        font-weight: 400;
        color: var(--color-red1-payment);
        font-size: 12px;
      }

      *.inputQty {
        @apply h-[30px] w-[50px] bg-transparent border-transparent text-center;
        padding-left: 4px;
        padding-right: 4px;

        &:focus,
        &:hover,
        &:active {
          @apply outline-none ring-1 ring-transparent ring-offset-0;
        }
      }

      *.buttonMinus,
      *.buttonPlus {
        @apply h-[30px] w-[30px];
        box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.1),
          0px 1px 3px rgba(0, 0, 0, 0.1);
        color: #000000 !important;
        background-color: #f1f4f7 !important;
        padding: 0px !important;
        border-radius: 5px !important;
        text-align: -webkit-center;
      }
    }
  }

  [class^='constainer-no-items'] {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  // =================== VmWrapCart ==================
  .VmWrapCart {
    overflow: hidden;
    background: #fdfdfd;
    // background: transparent;
    box-shadow: 0px 22px 70px 4px rgba(0, 0, 0, 0.07);
    border-radius: 12px 0px 0px 12px;
    height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 33%;

    &[class*='phone'] {
      margin-top: 23px;
      border-radius: 20px;
      min-width: auto;
    }

    .header {
      background: var(--color-white0-payment);
      box-shadow: 0px 22px 70px 4px rgba(0, 0, 0, 0.07);
      @apply py-4 px-6;
      color: var(--color-black0-payment);
      text-align: center;
      font-size: 20px;

      &[class*='phone'] {
        text-align: start;
        font-weight: 500;
        font-size: 14px;
        padding: 10px 15px;
        color: var(--color-white0-payment);
        background: linear-gradient(
          90.73deg,
          var(--color-blue1-payment) 0%,
          var(--color-blue0-payment) 100%
        );
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04),
          0px 8px 16px rgba(0, 0, 0, 0.08);
        border-radius: 10px 10px 0px 0px;
      }
    }

    .body {
      overflow-y: auto;
      height: -webkit-fill-available;

      .EmptyCart {
        @apply absolute flex flex-col-reverse justify-center items-center px-5;
        height: -webkit-fill-available;
        width: -webkit-fill-available;

        p {
          font-weight: 500;
          font-size: 14px;
          color: var(--color-blue1-payment);

          &:first-child {
            font-weight: 500;
            font-size: 10px;
            color: var(--color-gray3-payment);
          }
        }
      }
    }

    .footer {
      @apply flex flex-row justify-between;
      padding: 24px 12px 24px 12px;
      background: var(--color-white0-payment);
      box-shadow: 0px 22px 70px 4px rgba(0, 0, 0, 0.07);

      // Kalau class desktop
      &[class*='desktop'] {
        @apply ml-5 rounded-tl-xl;
      }

      &[class*='phone'] {
        @apply absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 15px 20px 10px 20px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04),
          0px -8px 16px rgba(0, 0, 0, 0.08);
        border-radius: 20px 20px 0px 0px;
      }

      *button {
        &[class*='bayar'] {
          margin-inline: 12px;
          background: linear-gradient(
            97.49deg,
            var(--color-yellow1-payment) 0%,
            var(--color-yellow0-payment) 100%
          );
          border-radius: 10px;
          font-weight: 600;
          font-size: 20px;
          min-width: 100px;
        }

        &[class*='phone'] {
          margin-left: 12px;
          margin-right: 0px;
          font-size: 14px;
          line-height: 18px;
        }
      }

      *.totalTitle,
      *.total {
        &[class*='phone'] {
          margin-left: 10px;
          margin-right: 0px;
        }
      }

      *.totalTitle {
        margin-inline: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        letter-spacing: 0.4px;
        color: var(--color-black0-payment);

        &[class*='phone'] {
          font-weight: 500;
          font-size: 11px;
          color: var(--color-blue3-payment);
        }
      }

      *.total {
        margin-inline: 10px;
        font-weight: 700;
        font-size: 26px;
        line-height: 30px;
        display: flex;
        align-items: flex-end;
        text-align: center;
        letter-spacing: 0.4px;
        color: var(--color-red0-payment);

        &[class*='phone'] {
          font-size: 20px;
        }
      }
    }
  }

  // =================== Data-Placeholder ==================
  [data-placeholder='true'] {
    @apply bg-gray-200 animate-pulse;
    color: transparent !important;
  }

  // =================== FullScreen ==================
  .FullScreen {
    @apply top-0 left-0 right-0 bottom-0;
    background: #d6d6d66e;
  }

  // =================== ProgressFullScreen ==================
  .ProgressFullScreen {
    @apply flex fixed FullScreen items-center justify-center;
    background: #d6d6d66e;
  }

  // =================== Button ==================
  .btn {
    @apply py-1 px-3 rounded text-base;

    &.sm {
      @apply px-2 py-1 text-sm;
    }

    &.lg {
      @apply p-3 text-lg;
    }
  }

  .page-item {
    @apply overflow-hidden;

    [class*='primary'] {
      @apply bg-blue-500;

      &[class*='outline'] {
        @apply border-x bg-transparent;

        &[class*='active'] {
          @apply primary-outline;
        }

        &:hover {
          @apply bg-blue-500;
        }
      }

      &[class*='ghost'] {
        @apply border-0 bg-transparent rounded-md;

        &[class*='active'] {
          @apply primary;
        }

        &:hover {
          @apply bg-blue-500;
        }
      }
    }

    [class*='secondary'] {
      @apply bg-stone-400;

      &[class*='outline'] {
        @apply border-x bg-transparent;

        &[class*='active'] {
          @apply secondary-outline;
        }

        &:hover {
          @apply bg-stone-400;
        }
      }

      &[class*='ghost'] {
        @apply border-0 bg-transparent rounded-md;

        &[class*='active'] {
          @apply secondary;
        }

        &:hover {
          @apply bg-stone-400;
        }
      }
    }

    [class*='success'] {
      @apply bg-emerald-500;

      &[class*='outline'] {
        @apply border-x bg-transparent;

        &[class*='active'] {
          @apply success-outline;
        }

        &:hover {
          @apply bg-emerald-500;
        }
      }

      &[class*='ghost'] {
        @apply border-0 bg-transparent rounded-md;

        &[class*='active'] {
          @apply success;
        }

        &:hover {
          @apply bg-emerald-500;
        }
      }
    }

    [class*='danger'] {
      @apply bg-red-500;

      &[class*='outline'] {
        @apply border-x bg-transparent;

        &[class*='active'] {
          @apply danger-outline;
        }

        &:hover {
          @apply bg-red-500;
        }
      }

      &[class*='ghost'] {
        @apply border-0 bg-transparent rounded-md;

        &[class*='active'] {
          @apply danger;
        }

        &:hover {
          @apply bg-red-500;
        }
      }
    }

    [class*='warning'] {
      @apply bg-yellow-500;

      &[class*='outline'] {
        @apply border-x bg-transparent;

        &[class*='active'] {
          @apply warning-outline;
        }

        &:hover {
          @apply bg-yellow-500;
        }
      }

      &[class*='ghost'] {
        @apply border-0 bg-transparent rounded-md;

        &[class*='active'] {
          @apply warning;
        }

        &:hover {
          @apply bg-yellow-500;
        }
      }
    }

    [class*='info'] {
      @apply bg-sky-500;

      &[class*='outline'] {
        @apply border-x bg-transparent;

        &[class*='active'] {
          @apply info-outline;
        }

        &:hover {
          @apply bg-sky-500;
        }
      }

      &[class*='ghost'] {
        @apply border-0 bg-transparent rounded-md;

        &[class*='active'] {
          @apply info;
        }

        &:hover {
          @apply bg-sky-500;
        }
      }
    }

    [class*='dark'] {
      @apply bg-slate-500;

      &[class*='outline'] {
        @apply border-x bg-transparent;

        &[class*='active'] {
          @apply dark-outline;
        }

        &:hover {
          @apply bg-slate-500;
        }
      }

      &[class*='ghost'] {
        @apply border-0 bg-transparent rounded-md;

        &[class*='active'] {
          @apply dark;
        }

        &:hover {
          @apply bg-slate-500;
        }
      }
    }

    [class*='light'] {
      @apply bg-slate-50;

      &[class*='outline'] {
        @apply border-x bg-transparent;

        &[class*='active'] {
          @apply light-outline;
        }

        &:hover {
          @apply bg-slate-50;
        }
      }

      &[class*='ghost'] {
        @apply border-0 bg-transparent rounded-md;

        &[class*='active'] {
          @apply light;
        }

        &:hover {
          @apply bg-slate-50;
        }
      }
    }

    &:first-child {
      @apply rounded-l border-y-0;
    }

    &:last-child {
      @apply rounded-r border-y-0;
    }
  }

  // =================== Colors ==================
  .primary:not(a) {
    @apply bg-blue-500 text-white;

    &:hover {
      @apply bg-blue-600;
    }

    &.active,
    &:active {
      @apply bg-blue-700;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-blue-600;
      }
    }
  }

  // Style untuk warna Text
  .text-primary {
    @apply text-blue-500;
  }

  .text-danger {
    @apply text-red-500;
  }

  .text-success {
    @apply text-emerald-500;
  }

  .text-secondary {
    @apply text-gray-500;
  }

  .secondary {
    @apply bg-stone-400 text-white;

    &:hover {
      @apply bg-stone-500;
    }

    &.active,
    &:active {
      @apply bg-stone-700;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-stone-500;
      }
    }
  }

  .success {
    @apply bg-emerald-500 text-white;

    &:hover {
      @apply bg-emerald-600;
    }

    &.active,
    &:active {
      @apply bg-emerald-700;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-emerald-600;
      }
    }
  }

  .danger {
    @apply bg-red-500 text-white;

    &:hover {
      @apply bg-red-600;
    }

    &.active,
    &:active {
      @apply bg-red-700;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-red-600;
      }
    }
  }

  .warning {
    @apply bg-yellow-500 text-white;

    &:hover {
      @apply bg-yellow-600/80;
    }

    &.active,
    &:active {
      @apply bg-yellow-600/80;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-yellow-500;
      }
    }
  }

  .info {
    @apply bg-sky-500 text-white;

    &:hover {
      @apply bg-sky-600;
    }

    &.active,
    &:active {
      @apply bg-sky-700;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-sky-600;
      }
    }
  }

  .dark {
    @apply bg-slate-500 text-white;

    &:hover {
      @apply bg-slate-600;
    }

    &.active,
    &:active {
      @apply bg-slate-700;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-slate-600;
      }
    }
  }

  .light {
    @apply bg-gray-100 text-gray-600;

    &:hover {
      @apply bg-gray-200;
    }

    &.active,
    &:active {
      @apply bg-stone-100;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-slate-200;
      }
    }
  }

  // =================== Outline ===================

  .primary-outline {
    @apply border border-blue-500 text-blue-500;

    &:hover {
      @apply bg-blue-500 text-slate-50;
    }

    &.active,
    &:active {
      @apply bg-blue-500 text-slate-50;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-blue-500;
      }
    }
  }

  .secondary-outline {
    @apply border border-stone-400 text-stone-400;

    &:hover {
      @apply bg-stone-400 text-white;
    }

    &.active,
    &:active {
      @apply bg-stone-400 text-white;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-stone-400;
      }
    }
  }

  .success-outline {
    @apply border border-emerald-500 text-emerald-500;

    &:hover {
      @apply bg-emerald-500 text-white;
    }

    &.active,
    &:active {
      @apply bg-emerald-500 text-white;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-emerald-500;
      }
    }
  }

  .danger-outline {
    @apply border border-red-500 text-red-500;

    &:hover {
      @apply bg-red-500 text-white;
    }

    &.active,
    &:active {
      @apply bg-red-500 text-white;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-red-500;
      }
    }
  }

  .warning-outline {
    @apply border border-yellow-500 text-yellow-500;

    &:hover {
      @apply bg-yellow-500 text-white;
    }

    &.active,
    &:active {
      @apply bg-yellow-500 text-white;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-yellow-500;
      }
    }
  }

  .info-outline {
    @apply border border-sky-500 text-sky-500;

    &:hover {
      @apply bg-sky-500 text-white;
    }

    &.active,
    &:active {
      @apply bg-sky-500 text-white;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-sky-500;
      }
    }
  }

  .dark-outline {
    @apply border border-slate-500 text-slate-500;

    &:hover {
      @apply bg-slate-500 text-white;
    }

    &.active,
    &:active {
      @apply bg-slate-500 text-white;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-slate-500;
      }
    }
  }

  .light-outline {
    @apply border border-slate-50 text-slate-100;

    &:hover {
      @apply bg-slate-50 text-slate-600;
    }

    &.active,
    &:active {
      @apply bg-stone-50 text-slate-600;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-slate-50;
      }
    }
  }

  // =================== Ghost ===================
  .primary-ghost {
    @apply text-blue-500;

    &:hover {
      @apply bg-blue-500 text-white;
    }

    &.active,
    &:active {
      @apply bg-blue-500 text-white;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-blue-500;
      }
    }
  }

  .secondary-ghost {
    @apply text-stone-400;

    &:hover {
      @apply bg-stone-400 text-white;
    }

    &.active,
    &:active {
      @apply bg-stone-500 text-white;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-stone-400;
      }
    }
  }

  .success-ghost {
    @apply text-emerald-500;

    &:hover {
      @apply bg-emerald-500 text-white;
    }

    &.active,
    &:active {
      @apply bg-emerald-500 text-white;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-emerald-500;
      }
    }
  }

  .danger-ghost {
    @apply text-red-500;

    &:hover {
      @apply bg-red-500 text-white;
    }

    &.active,
    &:active {
      @apply bg-red-500 text-white;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-red-500;
      }
    }
  }

  .warning-ghost {
    @apply text-yellow-500;

    &:hover {
      @apply bg-yellow-500 text-white;
    }

    &.active,
    &:active {
      @apply bg-yellow-500 text-white;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-yellow-500;
      }
    }
  }

  .info-ghost {
    @apply text-sky-500;

    &:hover {
      @apply bg-sky-500 text-white;
    }

    &.active,
    &:active {
      @apply bg-sky-500 text-white;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-sky-500;
      }
    }
  }

  .dark-ghost {
    @apply text-slate-500;

    &:hover {
      @apply bg-slate-500 text-white;
    }

    &.active,
    &:active {
      @apply bg-slate-500 text-white;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-slate-500;
      }
    }
  }

  .light-ghost {
    @apply text-slate-100;

    &:hover {
      @apply bg-slate-50 text-slate-600;
    }

    &.active,
    &:active {
      @apply bg-stone-100 text-slate-600;
    }

    &:focus {
      // Not apply when parent with role group
      :not([role='group']) > & {
        @apply outline-none ring-2 ring-offset-1 ring-offset-gray-100 ring-slate-50;
      }
    }
  }
}
