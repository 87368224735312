@layer components {
  .toaster {
    display: flex;
    position: fixed;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    width: 100%;
    padding: 0.5rem 1rem;
  }

  .toaster-top-full,
  .toaster-top-center,
  .toaster-top-right,
  .toaster-top-left,
  .toaster-bottom-full,
  .toaster-bottom-center,
  .toaster-bottom-right,
  .toaster-bottom-left {
    position: fixed;
    z-index: 1080;
    width: 350px;
  }

  .toaster-top-full,
  .toaster-top-center,
  .toaster-top-right,
  .toaster-top-left {
    top: 0;
  }

  .toaster-bottom-full,
  .toaster-bottom-center,
  .toaster-bottom-right,
  .toaster-bottom-left {
    bottom: 0;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .toaster-top-full,
  .toaster-bottom-full {
    width: auto;
  }

  .toaster-top-center,
  .toaster-bottom-center {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .toaster-top-full,
  .toaster-bottom-full,
  .toaster-top-right,
  .toaster-bottom-right {
    right: 0;
  }

  .toaster-top-full,
  .toaster-bottom-full,
  .toaster-top-left,
  .toaster-bottom-left {
    left: 0;
  }

  .toaster .toast {
    width: 100%;
    max-width: 100%;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }

  .toast {
    --toast-padding-x: 0.75rem;
    --toast-padding-y: 0.25rem;
    --toast-spacing: 1.5rem;
    --toast-max-width: 350px;
    --toast-font-size: 0.875rem;
    --toast-color: unset;
    --toast-bg: rgba(255, 255, 255, 0.797);
    --toast-border-width: 2px;
    --toast-border-radius: 1rem;
    --toast-header-color: #8a93a2;
    --toast-header-bg: rgba(255, 255, 255, 0.833);
    --toast-header-border-color: rgba(0, 0, 21, 0.05);

    &[class^='primary'],
    &[class^='secondary'],
    &[class^='success'],
    &[class^='danger'],
    &[class^='warning'],
    &[class^='info'],
    &[class^='dark'],
    &[class^='light'] {
      --toast-bg: rgba(255, 255, 255, 0);
      background-color: var(--toast-bg);
    }

    background-clip: padding-box;
    border-radius: var(--toast-border-radius);
    border-width: var(--toast-border-width);
    border-color: var(--toast-header-border-color);
    color: var(--toast-color);
    font-size: var(--toast-font-size);
    max-width: 100%;
    pointer-events: auto;
    overflow: hidden;
    width: var(--toast-max-width);

    .toast-header {
      --toast-header-border-color: rgba(239, 239, 239, 0.516);
      align-items: center;
      background-clip: padding-box;
      background-color: var(--toast-header-bg);
      border-bottom: var(--toast-border-width) solid
        var(--toast-header-border-color);
      border-top-left-radius: calc(
        var(--toast-border-radius) - var(--toast-border-width)
      );
      border-top-right-radius: calc(
        var(--toast-border-radius) - var(--toast-border-width)
      );
      color: var(--toast-header-color);
      display: flex;
      padding: var(--toast-padding-y) var(--toast-padding-x);

      &:last-child {
        border-bottom: 0;
        border-radius: calc(
          var(--toast-border-radius) - var(--toast-border-width)
        );
      }
    }

    .toast-body {
      --toast-bg: rgba(247, 247, 247, 0.789);
      word-wrap: break-word;
      padding: var(--toast-padding-x);
      background-color: var(--toast-bg);

      &:first-child {
        border-radius: calc(
          var(--toast-border-radius) - var(--toast-border-width)
        );
      }
    }
  }
}
