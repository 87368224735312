@tailwind components;

@layer components {
    .PatExamFooters {
        @apply bottom-4 left-0 right-0 flex flex-row justify-between;

        :first-child {
            @apply mx-4;

            &:not(:last-child) {
                @apply ml-4 mr-4;
            }
        }

        :last-child {
            @apply mx-4;

            &:not(:first-child) {
                @apply mr-4 ml-4;
            }
        }
    }
}