@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'fonts', 'vmComponents', 'vmInput', 'vmAccordion', 'vmSpinner',
  'vmToast', 'Cetak', 'vmColor';

#root,
body,
html {
  font-family: PoppinsRegular;
  min-height: 100vh;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  border-bottom: 4px solid #3498db;
  width: 120px;
  height: 120px;
  animation: spin 1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation: spin 1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@layer components {
  p {
    &[class^='error-'] {
      @apply flex flex-row bg-red-100 rounded-md text-stone-600 px-2 py-1 my-2 text-sm;
      width: inherit;
      max-width: fit-content;
    }

    &[class^='success-'] {
      @apply flex flex-row bg-green-100 rounded-md text-stone-600 px-2 py-1 my-2 text-sm;
      width: inherit;
      max-width: fit-content;
    }
  }

  // Bottom sheet
  [class*='bg-transparent'] {
    [data-rsbs-backdrop='true'] {
      background-color: transparent !important;
    }
  }
  [class*='tilt-transparent'] {
    [data-rsbs-header='true'] {
      &::before {
        background-color: transparent !important;
      }
    }
  }
  [class*='shadow-overlay-t'] {
    [data-rsbs-overlay='true'] {
      --tw-shadow: 0 0 90px -15px rgba(0, 0, 0, 0.3);
      --tw-shadow-colored: 0 0 90px -15px var(--tw-shadow-color);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }
  }
}

input[type='file']::-webkit-file-upload-button {
  display: none;
}

.react-datepicker__children-container {
  width: auto !important;
}

input[type='date'],
input[type='month'] {
  &[class*='hide-date-picker-indicator'] {
    &::-webkit-calendar-picker-indicator {
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      height: auto;
      cursor: pointer;
      position: absolute;
      color: transparent;
      background: transparent;
    }
  }
  &[class*='input-date-picker-transparent'] {
    &::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      cursor: pointer;
      height: auto;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: auto;
    }
    &::-webkit-datetime-edit {
      background: transparent;
      color: transparent;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    }
    &::-webkit-datetime-edit-fields-wrapper {
      background: transparent;
      color: transparent;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    }
    &::-webkit-datetime-edit-text {
      background: transparent;
      color: transparent;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    }
    &::-webkit-datetime-edit-month-field {
      background: transparent;
      color: transparent;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    }
    &::-webkit-datetime-edit-day-field {
      background: transparent;
      color: transparent;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    }
    &::-webkit-datetime-edit-year-field {
      background: transparent;
      color: transparent;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    }
    &::-webkit-inner-spin-button {
      background: transparent;
      color: transparent;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    }
    &::-webkit-calendar-picker-indicator {
      background: transparent;
      color: transparent;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    }
  }
}
