@tailwind components;

@layer components {
    .box {
        @apply w-full rounded overflow-hidden bg-white border-t-4 border-t-blue-400 shadow-md;

        &.box-info {
            @apply m-4 rounded overflow-hidden;
        }

        &.box-info {
            @apply m-4 rounded overflow-hidden;
        }
    }
}